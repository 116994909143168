@tailwind base;

@tailwind components;

@tailwind utilities;

/* applying default header styles to handle customer edited text through the admin functionality */
h1 {
  @apply font-bold;

  @apply text-3xl;

  @apply leading-10;

  @apply md:text-4xl;

  @apply md:leading-relaxed;
}

h2 {
  @apply font-bold;

  @apply text-2xl;

  @apply leading-8;
}

h3 {
  @apply font-bold;

  @apply text-xl;

  @apply leading-7;
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Poppins:wght@300&family=Playfair+Display&display=swap');

html,
body {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin-bottom: 1rem;
}

@layer base {
  .font-outline-2 {
    -webkit-text-stroke: 2px #ff3131;
  }
}

/* ---------- Navbar and Sidebar Menu Styles ----------*/
/*     
#navbar{
  border-bottom: 1px solid #fff;
}    
*/
ul.breadcrumb li span {
  opacity: 60%;
}

#sidebar {
  -webkit-transition: all 300ms cubic-bezier(0, 0.77, 0.58, 1);
  /* transition: all 300ms cubic-bezier(0, 0.77, 0.58, 1); */
  position: fixed;
  left: 0; /* Start at left: 0 */
  transition: left 300ms cubic-bezier(0, 0.77, 0.58, 1);
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
  height: 45%;
  margin: 0;
  padding: 0;
  border-left: 1px solid #003474;
  border-right: 1px solid #003474;
  border-bottom: 1px solid #003474;
}

#sidebar::-webkit-scrollbar {
  background: transparent; /* Chrome/Safari/Webkit */
  width: 0px;
}

#sidebar.show {
  /* transform: translateX(0); */
  left: 100%; 
}

#sidebar.hide {
  display: none;
}

/* ---------- Modal Active Overlay ----------*/

body.active-modal {
  overflow-y: hidden;
}

/* ----- hamburger line styles --------- */
.burger-bar {
  width: 32px;
  height: 4px;
  background-color: #003474;
  border-radius: 0.5em;
  margin-bottom: 6px;
}

/* ----- hamburger icon animations --------- */
/* clicked */
.burger-bar.clicked:nth-child(1) {
  transform: rotate(45deg) translate(0.4em, .5em);
  transition: ease-out 0.3s;
}

.burger-bar.clicked:nth-child(2) {
  transform: scale(0.0);
  transition: ease-out 0s;
}

.burger-bar.clicked:nth-child(3) {
  transform: rotate(-45deg) translate(0.4em, -.5em);
  transition: ease-out 0.3s;
}

/* unclicked */
.burger-bar.unclicked {
  transform: rotate(0) translate(0);
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.5s;
}

/* ----- Donate page logo animations --------- */
.header-y {
  transform: translateY(-100%);
  transition: transform 0.5s ease-in-out;
}

.header-x {
  transform: translateX(-100%);
  transition: transform 0.5s ease-in-out;
}

.visible-y {
  transform: translateY(0);
}

.visible-x {
  transform: translateX(0);
}